<template>
  <div>
    <div class="sctp-bg-white">
      <div class="flex">
        <div style="width: 600px" class="sctp-pad-lr15 sctp-pad-tb10">
          <el-form :rules="formRules" size="small" ref="form" :model="form" label-width="160px">
            <el-form-item label="当前的余额">
              <span class="sctp-font-16 sctp-bold">{{ wallet.toMoney() }}</span> 元
              <template v-if="waitWithdrawWallet">
                (提现待审核金额：<span class="sctp-font-16 sctp-bold">{{ waitWithdrawWallet }}</span> 元)
              </template>
            </el-form-item>
            <el-form-item label="提现金额" prop="money">
              <el-input v-model="form.money">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="提现方式" prop="way">
              <el-radio-group v-model="form.way" @change="blue">
                <el-radio :label="1">支付宝</el-radio>
                <el-radio :label="2">微信</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="`${{1:'支付宝',2:'微信'}[form.way]||''}账号`" prop="account">
              <el-input v-model="form.account"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="核对姓名" prop="realName">
              <el-input v-model="form.realName"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitClick">立即提现</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div style="padding: 30px 15px;width: 300px;">
          <h1 class="mg-b10">温馨提示：</h1>
          <div class="fz-16 sctp-lh-medium">
            <div>1、如果余额少于50元，按照平台规定，维护交易双方可能存在的纠纷，自最后一笔交易算起的三个月内不能提现，感谢您的理解！</div>
            <div>2、如果余额多余50元，提现最高金额为当前余额减50元</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validator from '../../../../resources/js/async-validator'
import {wallet} from "../../../../apis";

export default {
  name: 'account_withdraw',
  data() {
    return {
      form: {
        money: null,
        way: null,
        account: null,
        phone: null,
        realName: null,
      },
      wallet: 0,
      waitWithdrawWallet: 0,
      formRules: {
        money: [
          {required: true, message: '请输入需要提现的金额', trigger: 'blur'},
          {validator: validator.validMoney, allowedZero: false, message: '金额不合法', trigger: ['change', 'blur']},
          {
            validator: (rule, value, callback) => {
              let {wallet: data} = this;
              if (data > 50 && value > (data - 50)) {
                callback(new Error(`允许提现最大金额${data - 50}`))
                return;
              }
              if (value > data) {
                callback(new Error(`允许提现最大金额${data}`))
                return;
              }
              wallet.checkWhetherWithdrawAble({
                userId: this.user.userId,
                amount: value
              }).then(res => {
                let {data, retDesc} = res;
                if (data) {
                  callback()
                } else {
                  callback(new Error(retDesc))
                }
              })
            },
            trigger: ['change', 'blur']
          }
        ],
        way: [
          {required: true, message: '请选择提现方式', trigger: ['blur', 'change']},
        ],
        account: [
          {required: true, message: '请输入收款账号', trigger: ['blur']},
          {
            validator: (rule, value, callback) => {
              if (this.form.way == 1){

                if (value.indexOf('@') != -1){
                  let emailVal = /^([A-Za-z0-9_.])+@([A-Za-z0-9_.])+\.([A-Za-z]{2,4})$/;
                  if (!emailVal.test(value)) {
                    callback(new Error('输入的邮箱格式有误'));
                  }else {
                    callback()
                  }
                }

                if (!/^1[3456789]\d{9}$/.test(value)){
                  callback(new Error('输入的手机号格式有误'));
                }else {
                  callback()
                }
              }else {
                let vals = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/
                if (!vals.test(value)) {
                  callback(new Error('输入的微信号格式有误'));
                }else {
                  callback()
                }
              }
            },
            trigger: ['blur']
          }
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: ['blur', 'change']},
          {validator: validator.validPhone, message: '手机号格式不正确', trigger: ['blur']},
        ],
        realName: [
          {required: true, message: '请输入真实姓名', trigger: ['blur', 'change']},{
            validator: (rule, value, callback) => {
              var val = /^[\u4e00-\u9fa5a-zA-Z]{2,4}$/
              if (!val.test(value)){
                callback(new Error('真实姓名格式错误！'))
              }else{
                callback()
              }
            },
            trigger: ['blur']
          }
        ],
      }
    }
  },
  methods: {
    onSubmitClick() {
      this.$refs.form.validate(res => {
        if (res) {
          this.doSubmit()
        }
      })
    },
    /**
     * 充值逻辑
     */
    doSubmit() {
      let {form} = this;
      wallet.applyForWithdrawal({
        username: form.realName,
        phone: form.phone,
        amount: form.money,
        account: form.account,
        way: form.way,
        userId: this.user.userId
      }).then(res => {
        this.$message.success("提交申请成功，等待审核！");
        this.getWallet();
        this.$refs['form'].resetFields();
      })
    },
    getWallet() {
      return wallet.getWallet({
        userId: this.user.userId
      }).then(res => {
        const {data = 0, waitAuthWallet = 0} = res;
        this.wallet = data;
        this.waitWithdrawWallet = waitAuthWallet;
      })
    },
    blue(){

      if (this.form.account){
        this.$refs['form'].validateField('account')
      }

    }
  },
  beforeMount() {
    this.getWallet()
  }
}
</script>

<style scoped>
/*@formatter:off*/

  /*@formatter:on*/
</style>
